.overlay {
  // position: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  overflow: hidden;
  opacity: 0;

  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.visible {
  opacity: 1;
}
.hidden {
  opacity: 0;
}

.modal {
  background-color: #fff;
  min-width: 100px;
  max-width: 90vw;
  max-height: 100vh;
  border-radius: 1.25rem;
  padding: 1.5rem;
  z-index: 1000;
  overflow-y: auto;
}
