.container {
  display: flex;
  // flex: 1;
  justify-content: space-between;
  padding: 38px 50px;

  border: var(--testing-border);

  height: 126px;
}

.logoContainer {
  align-self: center;
  width: 130px;
}

.logoContainerSmall {
  align-self: center;
}

.containerMobile {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  justify-items: center;
  align-items: center;
}
