@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #f9f9f9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  overflow-y: scroll;
  overflow-y: overlay;
  padding: 0;
  margin: 0;

  font-family: 'Noto Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  font-size: 1rem;

  background-color: var(--color-BgGreen);
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;

  border: var(--testing-border);
}

:root {
  --layout-padding-vertical: 38px;

  // theme
  --font-primary: 'Noto Sans', sans-serif;
  --font-secondary: 'Roboto', sans-serif;

  --color-BgGreen: #dbfff1;
  --color-Green300: #00fa96;
  --color-Green700: #18c67f;
  --color-Green800: #006941;
  --color-Green900: #00331e;
  --color-SecondaryColorCaution: #ba8c00;
  --color-BackgroundColorCaution: #fff2c9;
  --color-SecondaryColorRed: #ff7d7d;
  --color-BackgroundColorRed: #ffecfd;
  --color-BackgroundColorBgBlue: rgba(186, 218, 255, 0.6);
  --color-SecondaryColorBlue: #7da2ff;
  --color-SecondaryColorWhite: #fff;
  --color-TextPrimary: #000;
  --color-Grey900: #202124;
  --color-lt-text-primary: #000000;
  --color-lt-secondary: #4a4a4a;
  --color-dt-text-primary: #e6e6e6;

  // must also change in tailwind.config.js
  --breakpoint-sm: 576px;

  // --testing-border: 2px solid blue;
  // --testing-border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.phoneinput-container {
  .react-tel-input {
    input.form-control {
      border: none !important;
      width: 100%;
      background-color: white !important;
    }
    .flag-dropdown {
      border: none;
    }
  }
}

h6 {
  @apply font-Roboto font-medium leading-6 text-lg;
}
